@import 'colors';
@import 'fonts';
@import 'sizes';
@import 'atoms';

$enable-shadows: 'true';
$enable-rounded: 'true';

:root {
    --cards-shadow: 1px 1px 9px #e4e3e8;
}