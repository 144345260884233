.organisms-card {
    border: 1px solid var(--default-border-color);
    background: var(--color-white);
    border-radius: 4px;
    &__header {
        border-bottom: 1px solid var(--default-border-color);
        padding: var(--space-xxs) var(--space-xs);
        &:empty {
            display: none;
        }
    }
    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        & img {
            max-width: 100%;
        }
        &:empty {
            display: none;
        }
    }
    &__body {
        padding: var(--space-xxs) var(--space-xs);
    }
    &__footer {
        padding: var(--space-xxs) var(--space-xs);
        border-top: 1px solid var(--default-border-color);
        &:empty {
            display: none;
        }
    }
}