@mixin button-state-hover() {
	&:hover, &.hover {
		@content;
	}

}
@mixin button-state-active() {
	&:active, &.active {
		@content;
	}
}
@mixin button-state-focus() {
	&:focus, &.focus {
		@content;
	}
}	
@mixin button-state-focus-active() {
	&:active:focus, &.focus:active, &.focus.active, &.active:focus {
		@content;
	}
}

@mixin button-state-disabled($state) {
	@if $state {
		&:disabled, &.disabled {
			@content;
		}
	}
	@else {
		&:not(:disabled):not(.disabled) {
			@content;
		}
	}
	
}
