.list{
    padding-left: 0;
    &_number {
        counter-reset: li; 
        list-style: none; 
        margin: 0;
        padding: 0;
        
        & > li {
            position: relative;
            font-size: 16px;
            line-height: 20px;
            padding:16px 160px 16px 64px;
            border-bottom: 1px solid #eeeff3;
            &:before {
                position: absolute;
                left: 16px;
                top: 12px;
                width: 24px;
                height: 24px;
                content: counter(li);
                counter-increment: li;
                font-size: 12px;
                line-height: 25px;
                text-align: center;
                color: #fff;
                border-radius: 12px;
                background: $primary;
            }
        }

    }
    &_bullets{
        padding: 10px 0 0;
        list-style: none;

        & > li {
            padding-left: 20px;
            line-height: 20px;
            margin-bottom: 4px;
            position: relative;
            &:before {
                position: absolute;
                left: 5px;
                top: 6px;
                content: '';
                height: 6px;
                width: 6px;
                border-radius: 3px;
                background: $primary;
            }
        }
    } 
    &_lg {
        & > li {
            padding-top: 20px;
            padding-bottom: 20px;
            margin-left: 18px;
        }
        &.list_bullets {
            padding-top: 0;
            & > li{
                padding-top: 20px;
                padding-bottom: 20px;
                margin-bottom: 0;
                padding-left: 0;
                
                &:before {
                    position: absolute;
                    left: -18px;
                    top: 28px;
                    content: '';
                    height: 6px;
                    width: 6px;
                    border-radius: 3px;
                    background: $primary;
                }
            }
        }
    }
    &_border-bottom {
        & > li {
            border-bottom: 1px solid var(--color-border-gray);
        }
    }  
    
}

@mixin empty-slot {
    &:empty {
        display: none;
        margin-left: 0;

        &+.molecules_list-item__slot {
            margin-left: 0;
        }
    }
}

.molecules_list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--text-truncate {
        & > .molecules_list-item__slot {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    & > .molecules_list-item__slot {
        height: inherit;
        @include empty-slot;
    }
   
    // Размеры (Size)
    &--xs {
        $spacerXsVertical: var(--space-xxxs);
        $spacerXsHorizontal: var(--space-xs);
    
        padding: $spacerXsVertical $spacerXsHorizontal;
        & > .molecules_list-item__slot {
            $gap: var(--space-xxxxs);
            min-height: var(--space-xxs);

            margin-left: $spacerXsHorizontal;
            @include empty-slot;

            &--position-center {
                margin-left: $gap;
            }

            &--position-right {
                margin-left: $gap;
            }
        }
    }

    &--md {
        $spacerMdVertical: var(--space-xxs);
        $spacerMdHorizontal: var(--space-sm);

        padding: $spacerMdVertical $spacerMdHorizontal;

        & > .molecules_list-item__slot {
            $gap: var(--space-xxs);
            min-height: var(--space-xs);

            @include empty-slot;

            &--position-center {
                margin-left: $gap;
            }

            &--position-right {
                margin-left: $gap;
            }
        }
    }

    &--lg {
        $spacerLgVertical: var(--space-sm);
        $spacerLgHorizontal: var(--space-lg);

        padding: $spacerLgVertical $spacerLgHorizontal;
        line-height: 1rem;

        & > .molecules_list-item__slot {
            $gap: var(--space-xxxxs);
            min-height: var(--space-xxs);

            margin-left: $spacerLgHorizontal;
            @include empty-slot;

            &--position-center {
                margin-left: $gap;
            }

            &--position-right {
                margin-left: $gap;
            }
            
        }
    }

    // Темы (Theme)
    &--default-theme {
        background-color: var(--color-secondary-lighter);
    }

    // Типы разметки контента (GridContant)
    &--default-grid {
        & > .molecules_list-item__slot {
            flex-shrink: 0;

            &--position-center {
                flex-grow: 1;
                flex-shrink: 1;
            }
        }
    }
}
