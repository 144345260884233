//Button success colors

    //default
        $button-success-default-bg-color: $success; // Основной цвет кнопки
        $button-style-success-bg-color: $button-success-default-bg-color; //Основной цвет фона кнопки
        $button-style-success-color: $white; // Основной цвет текста кнопки
        $button-style-success-border-color: $button-success-default-bg-color; // Основной цвет обводки 

    //hover
        $button-style-success-bg-color-hover: transparentize($button-success-default-bg-color,.6); // Цвет фона при наведени
        $button-style-success-border-color-hover: transparentize($button-success-default-bg-color,.6); // Цвет обводки при наведени
        $button-style-success-color-hover:  $button-style-success-color; // Цвет текста при наведении
    //active
        $button-style-success-bg-color-active:  $button-success-default-bg-color; // Цвет фона при active
        $button-style-success-border-color-active:  $button-success-default-bg-color; // Цвет обводки при active
        $button-style-success-color-active:  $button-style-success-color; // Цвет текста при active

//Button outline-success colors
    //default
        $button-style-outline-success-bg-color: transparent; // Основной цвет фона кнопки
        $button-style-outline-success-color: $black; // Основной цвет текста кнопки
        $button-style-outline-success-border-color: $button-success-default-bg-color; // Основной цвет обводки 

    //hover
        $button-style-outline-success-bg-color-hover: $button-success-default-bg-color; // Цвет фона при наведени
        $button-style-outline-success-border-color-hover: $button-success-default-bg-color; // Цвет обводки при наведени
        $button-style-outline-success-color-hover: $white; // Цвет текста при наведении
    //active
        $button-style-outline-success-bg-color-active: $button-success-default-bg-color; // Цвет фона при active
        $button-style-outline-success-border-color-active: $button-success-default-bg-color; // Цвет обводки при active
        $button-style-outline-success-color-active: $white; // Цвет текста при active