.btn {
	//Основные стили кнопки
	display: inline-block;
	font-weight: $button-typography-weight;
	text-align: $button-typography-align;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	text-decoration: none;
	border-style: solid;
	@include box-shadow($button-style-shadow);
	@include border-radius($button-border-radius);
	@include button-state-disabled(false) {
		cursor: pointer;
	}

	&-primary {
		@include button-type($button-style-primary-bg-color, $button-style-primary-border-color, $button-style-primary-color);
	}

	&-secondary {
		@include button-type($button-style-secondary-bg-color, $button-style-secondary-border-color, $button-style-secondary-color);
	}

  	&-link {
		@include button-type($button-style-link-bg-color, $button-style-link-border-color, $button-style-link-color);
	}

	&-success {
		@include button-type($button-style-success-bg-color, $button-style-success-border-color, $button-style-success-color);
	}

	&-danger {
		@include button-type($button-style-danger-bg-color, $button-style-danger-border-color, $button-style-danger-color);
	}

	&-warning {
	 	@include button-type($button-style-warning-bg-color, $button-style-warning-border-color, $button-style-warning-color);
	}

	&-info {
		@include button-type($button-style-info-bg-color, $button-style-info-border-color, $button-style-info-color);
	}

	&-outline {
		&-primary {
			@include button-outline-type($button-style-outline-primary-bg-color, $button-style-outline-primary-border-color, $button-style-outline-primary-color);
		}

		&-secondary {
			@include button-outline-type($button-style-outline-secondary-bg-color, $button-style-outline-secondary-border-color, $button-style-outline-secondary-color);
		}
	
		 &-success {
		 	@include button-outline-type($button-style-outline-success-bg-color, $button-style-outline-success-border-color, $button-style-outline-success-color);
		}
	
		&-danger {
			@include button-outline-type($button-style-outline-danger-bg-color, $button-style-outline-danger-border-color, $button-style-outline-danger-color);
		}
	
		&-warning {
			@include button-outline-type($button-style-outline-warning-bg-color, $button-style-outline-warning-border-color, $button-style-outline-warning-color);
		}
	
		&-info {
			@include button-outline-type($button-style-outline-info-bg-color, $button-style-outline-info-border-color, $button-style-outline-info-color);
		}
	}
	
	&-bordered {
		&-primary {
			@include button-bordered-type($button-style-bordered-primary-bg-color, $button-style-bordered-primary-border-color, $button-style-bordered-primary-color);
		}
	}
	
	&-selected {
		position: relative;
		padding-right: 26px !important;
		&:before {
			content: "\e90e";
			top: 50%;
			margin-top: -5px;
			right: 14px;
			font-family: 'icomoon';
			position: absolute;
			font-size: 9px;
			line-height: 9px;
		}
		&_dropdown {
			&:before {
				transform: rotate(-90deg);
			}
			&.expand {
				&:before {
					transform: rotate(0deg);
				}
			}
		}
	}
}
