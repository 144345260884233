// --------------------------------

// (START) Global Editor code https://codyhouse.co/ds/globals/colors

// --------------------------------

:root{
  /* Основная палитра по типам */
  @include defineColorHSL(--color-primary-darker, 222, 76%, 39%);
  @include defineColorHSL(--color-primary-dark, 223, 76%, 49%);
  @include defineColorHSL(--color-primary, 223, 76%, 59%);
  @include defineColorHSL(--color-primary-light, 223, 76%, 69%);
  @include defineColorHSL(--color-primary-lighter, 223, 76%, 79%);

  @include defineColorHSL(--color-accent-darker, 0, 100%, 30%);
  @include defineColorHSL(--color-accent-dark, 0, 100%, 40%);
  @include defineColorHSL(--color-accent, 0, 100%, 50%);
  @include defineColorHSL(--color-accent-light, 0, 100%, 60%);
  @include defineColorHSL(--color-accent-lighter, 0, 100%, 70%);

  @include defineColorHSL(--color-secondary-darker, 210, 21%, 73%);
  @include defineColorHSL(--color-secondary-dark, 210, 21%, 83%);
  @include defineColorHSL(--color-secondary, 210, 21%, 93%);
  @include defineColorHSL(--color-secondary-light, 210, 21%, 96%);
  @include defineColorHSL(--color-secondary-lighter, 210, 21%, 100%);

  @include defineColorHSL(--color-black, 210, 11%, 15%);
  @include defineColorHSL(--color-white, 0, 0%, 100%);

  @include defineColorHSL(--color-warning-darker, 47, 78%, 41%);
  @include defineColorHSL(--color-warning-dark, 47, 78%, 51%);
  @include defineColorHSL(--color-warning, 47, 78%, 61%);
  @include defineColorHSL(--color-warning-light, 47, 78%, 71%);
  @include defineColorHSL(--color-warning-lighter, 47, 78%, 81%);

  @include defineColorHSL(--color-success-darker, 134, 61%, 21%);
  @include defineColorHSL(--color-success-dark, 134, 61%, 31%);
  @include defineColorHSL(--color-success, 134, 61%, 41%);
  @include defineColorHSL(--color-success-light, 134, 61%, 51%);
  @include defineColorHSL(--color-success-lighter, 134, 61%, 61%);

  @include defineColorHSL(--color-error-darker, 0, 100%, 30%);
  @include defineColorHSL(--color-error-dark, 0, 100%, 40%);
  @include defineColorHSL(--color-error, 0, 100%, 50%);
  @include defineColorHSL(--color-error-light, 0, 100%, 60%);
  @include defineColorHSL(--color-error-lighter, 0, 100%, 70%);

  @include defineColorHSL(--color-info-darker, 188, 78%, 21%);
  @include defineColorHSL(--color-info-dark, 188, 78%, 31%);
  @include defineColorHSL(--color-info, 188, 78%, 41%);
  @include defineColorHSL(--color-info-light, 188, 78%, 51%);
  @include defineColorHSL(--color-info-lighter, 188, 78%, 61%);

  /* Основная палитра по цветам */
  @include defineColorHSL(--color-blue-darker, 223, 100%, 45%);
  @include defineColorHSL(--color-blue-dark, 223, 100%, 55%);
  @include defineColorHSL(--color-blue, 223, 100%, 65%);
  @include defineColorHSL(--color-blue-light, 223, 100%, 75%);
  @include defineColorHSL(--color-blue-lighter, 223, 100%, 85%);

  @include defineColorHSL(--color-orange-darker, 36, 100%, 38%);
  @include defineColorHSL(--color-orange-dark, 36, 100%, 48%);
  @include defineColorHSL(--color-orange, 36, 100%, 58%);
  @include defineColorHSL(--color-orange-light, 36, 100%, 68%);
  @include defineColorHSL(--color-orange-lighter, 36, 100%, 78%);

  @include defineColorHSL(--color-turquoise-darker, 169, 81%, 48%);
  @include defineColorHSL(--color-turquoise-dark, 169, 81%, 58%);
  @include defineColorHSL(--color-turquoise, 169, 81%, 68%);
  @include defineColorHSL(--color-turquoise-light, 169, 81%, 78%);
  @include defineColorHSL(--color-turquoise-lighter, 169, 81%, 88%);

  @include defineColorHSL(--color-yellow-darker, 50, 85%, 33%);
  @include defineColorHSL(--color-yellow-dark, 50, 85%, 43%);
  @include defineColorHSL(--color-yellow, 50, 85%, 53%);
  @include defineColorHSL(--color-yellow-light, 50, 85%, 63%);
  @include defineColorHSL(--color-yellow-lighter, 50, 85%, 73%);

  @include defineColorHSL(--color-purple-darker, 263, 100%, 47%);
  @include defineColorHSL(--color-purple-dark, 263, 100%, 57%);
  @include defineColorHSL(--color-purple, 263, 100%, 67%);
  @include defineColorHSL(--color-purple-light, 263, 100%, 77%);
  @include defineColorHSL(--color-purple-lighter, 263, 100%, 87%);

  @include defineColorHSL(--color-pink-darker, 303, 85%, 18%);
  @include defineColorHSL(--color-pink-dark, 303, 85%, 28%);
  @include defineColorHSL(--color-pink, 303, 85%, 38%);
  @include defineColorHSL(--color-pink-light, 303, 85%, 48%);
  @include defineColorHSL(--color-pink-lighter, 303, 85%, 58%);

  // color gray
  @include defineColorHSL(--color-gray-darker, 0, 0%, 72%);
  @include defineColorHSL(--color-gray-dark, 0, 0%, 82%);
  @include defineColorHSL(--color-gray, 0, 0%, 92%);
  @include defineColorHSL(--color-gray-light, 0, 0%, 96%);
  @include defineColorHSL(--color-gray-lighter, 0, 0%, 100%);

  // color contrasts
  @include defineColorHSL(--color-bg, 0, 0%, 100%);
  @include defineColorHSL(--color-contrast-lower, 0, 0%, 95%);
  @include defineColorHSL(--color-contrast-low, 240, 1%, 83%);
  @include defineColorHSL(--color-contrast-medium, 240, 1%, 48%);
  @include defineColorHSL(--color-contrast-high, 240, 4%, 20%);
  @include defineColorHSL(--color-contrast-higher, 240, 8%, 12%);

}

// --------------------------------

// (END) Global Editor Code

// --------------------------------
