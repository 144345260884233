@import "../variables/variables";
.sampling-selection {
    margin-top: 10px;

    &__heading {
        margin-bottom: 10px;
    }

    &__list {
        display: flex;
        // margin-bottom: -15px; //TODO Закоменнтированно по причине сильного сближения с границей при ваывоже сообщения
        flex-wrap: wrap;
    }

    &__item {
        margin-right: 15px;
        margin-bottom: 15px;

        &-text {
            padding: 12px 15px;
        }
    }
}

$color_gray: #6e6e6e;
$color_light-gray: #ebebeb;
$color_light-green: rgba(149, 223, 206, .5);
$color_lime: $primary;

//TODO После проверки страниц на отсутствие ошибок удалить данный код
// .button {
//     max-width: 100%;
//     display: inline-block;
//     position: relative;
//     padding: 0px;
//     border-radius: 5px;
//     text-decoration: none !important;   
//     transition: opacity 100ms ease;
//     cursor: pointer;

//     &-default {
//         border: 1px solid $color_light-gray;
//         color: $color_gray !important;

//         &:hover {
//             border-color: $color_light-green;
//         }

//         &_active {
//             border-color: $color_lime;
//         }
//     }

//     &_disabled {
//         color: $color_light-gray;
//         cursor: auto;

//         &:hover {
//             border-color: $color_light-gray;
//         }
//     }
// }