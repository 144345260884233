// Box shadow
:root {
    --shadow-sm: -1px 1px 6px rgba(0, 0, 0, 0.06);
    --shadow-md: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.06);
}
// --------------------------------

//Описанный дизайнером набор теней
.shadow--sm { box-shadow: var(--shadow-sm); }
.shadow--md { box-shadow: var(--shadow-md); }
.shadow--unset { box-shadow: none; }

// Данный набор теней не описан дизайнером
//.shadow--xs { box-shadow: var(--shadow-xs); }
// .shadow--lg { box-shadow: var(--shadow-lg); }
// .shadow--xl { box-shadow: var(--shadow-xl); }
