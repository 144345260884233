//Button danger colors

    //default
        $button-danger-default-bg-color: $danger; // Основной цвет кнопки
        $button-style-danger-bg-color: $button-danger-default-bg-color; //Основной цвет фона кнопки
        $button-style-danger-color: $white; // Основной цвет текста кнопки
        $button-style-danger-border-color: $button-danger-default-bg-color; // Основной цвет обводки 

    //hover
        $button-style-danger-bg-color-hover: transparentize($button-danger-default-bg-color,.6); // Цвет фона при наведени
        $button-style-danger-border-color-hover: transparentize($button-danger-default-bg-color,.6); // Цвет обводки при наведени
        $button-style-danger-color-hover:  $button-style-danger-color; // Цвет текста при наведении
    //active
        $button-style-danger-bg-color-active:  $button-danger-default-bg-color; // Цвет фона при active
        $button-style-danger-border-color-active:  $button-danger-default-bg-color; // Цвет обводки при active
        $button-style-danger-color-active:  $button-style-danger-color; // Цвет текста при active

//Button outline-danger colors
    //default
        $button-style-outline-danger-bg-color: transparent; // Основной цвет фона кнопки
        $button-style-outline-danger-color: $black; // Основной цвет текста кнопки
        $button-style-outline-danger-border-color: $button-danger-default-bg-color; // Основной цвет обводки 

    //hover
        $button-style-outline-danger-bg-color-hover: $button-danger-default-bg-color; // Цвет фона при наведени
        $button-style-outline-danger-border-color-hover: $button-danger-default-bg-color; // Цвет обводки при наведени
        $button-style-outline-danger-color-hover: $white; // Цвет текста при наведении
    //active
        $button-style-outline-danger-bg-color-active: $button-danger-default-bg-color; // Цвет фона при active
        $button-style-outline-danger-border-color-active: $button-danger-default-bg-color; // Цвет обводки при active
        $button-style-outline-danger-color-active: $white; // Цвет текста при active 