:root {
    --h-grid-size-point: var(--h-grid-size-point, 0.5rem);
    --h-grid-size-scale: var(--h-grid-size-scale, 7);


    --space-unit:  var(--space-unit, 1rem);

    --space-xxxxs: calc(0.25 * var(--space-unit));
    --space-xxxs: calc(0.5 * var(--space-unit));
    --space-xxs: calc(0.75 * var(--space-unit));
    --space-xs: calc(1 * var(--space-unit));
    --space-sm: calc(1.25 * var(--space-unit));
    --space-md: calc(1.75 * var(--space-unit));
    --space-lg: calc(2.25 * var(--space-unit));
    --space-xl: calc(2.75 * var(--space-unit));
    --space-xxl: calc(3.25 * var(--space-unit));
    --space-xxxl: calc(5 * var(--space-unit));
    --space-xxxxl: calc(7 * var(--space-unit));

    //TODO Для сравнения старых и новых отступов, после проверки страниц с изменеными  отступами , данный раздел можно убрать.
    // --space-xxxs:   calc(0.25 * var(--space-unit));
    // --space-xxs:   calc(0.5 * var(--space-unit));
    // --space-xs:    calc(0.75 * var(--space-unit));
    // --space-sm:    calc(1 * var(--space-unit)); 
    // --space-md:    calc(1.25 * var(--space-unit));
    // --space-lg:    calc(2 * var(--space-unit));
    // --space-xl:    calc(3.25 * var(--space-unit));
    // --space-xxl:   calc(5.25 * var(--space-unit));
    // --space-xxxl:  calc(8.5 * var(--space-unit));

}
