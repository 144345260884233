@import 'codyhouse/style';
@import 'variables/variables';
@import 'grid/index';
@import 'templates/grid-templates';
@import 'mixins/common-mixin';
@import 'forms/form';
@import 'forms/input';
@import 'forms/selects';
@import 'components/accordion';
@import 'components/lists';
@import 'components/table';
@import 'components/modal';
@import 'components/buttons/index';
@import 'components/sampling-selection';
@import 'components/text';
@import 'components/slider/slider';
@import 'components/tabs/index';
@import 'components/colors/colors';
@import 'components/background/background';
@import 'components/radio/radio';
@import 'components/card/card';
@import 'components/tag/tag';
@import 'components/collapse/collapse';
@import 'components/divider';
@import 'components/loader/loader';

$colorLegendGrey: #b8b8b8;
$colorLegendBlack: black;

body {
	font-family: $font-stack;
	color: $font-default-color;
	
}
.page {
	padding-top: 106px;
	&__title {
		margin-bottom: 56px;
		font-size: 40px;
		line-height: 56px;
		font-weight: 500;
	}
}
.sign-off {
	border: 1px solid transparentize($primary,.8);
	font-size: 20px !important;
	color: #333 !important;
	padding: 7px 6px 7px 8px;
	border-radius: 5px;
	&:hover {
		border-color: $primary;
	}
}
.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;

	width: 100%;
	min-height: 56px;
	padding-top: 12px;

	border-bottom: 2px solid rgba(207,207,208, 0.2);
	background: #fff;
	&__logo {
		display: inline-block;
		padding-top: 4px;

		font-size: 16px;
		color: $color-brand;
  		font-weight: 500;
  		line-height: 1.5;

  		&:hover {
  			color: $color-brand;
  			text-decoration: none;
  		}
  		&:active {
  			color: $color-brand;
  			text-decoration: none;
  		}
  		&:focus {
  			color: $color-brand;
  			text-decoration: none;
  		}
	}
}
.lead {
	font-size: .875rem;
	line-height: 1rem;
	color: $font-lighter-color;
}
.blockquot {
	font-size: 1rem; 
	line-height: 1.5rem;
	color: $font-lighter-color;

}
.calendar-dropdown-view__menu {
    display: none;
	background-color: #fff;
	min-width: 200px;

    &.show {
        position: absolute;
        display: block;
		left: 0;
		top: 48px;
		right: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
       	padding: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        border-radius: 5px;
        border: 1px solid #ebebeb;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, .05);
    }

    &.dropdown-menu {
        margin: 0;
        padding: 0;
        border: none;
        border-radius: 0;
        box-shadow: 0 8px 24px 0 rgba(22, 102, 167, 0.2);
    }

    .filter-button.dd-filter-button {
		display: block;
		width: 100%;
		border: none !important;
		margin-bottom: 0 !important;
		border-radius: 0 !important;
		margin-right: 0;
		&_icons {
			display: flex;
			.dropdown-item__text {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				min-width: 0;
			}
		}
	}
}
.signin {
	background: #f6f7f7 !important;
}
h1, .h1 {
	font-size: $h1-font-size;
}
h2, .h2 {
	font-size: $h2-font-size;
}
h3, .h3 {
	font-size: $h3-font-size;
}
h4, .h4 {
	font-size: $h4-font-size;
}

.page-header {
	background:$white;
	font-size: 0.875rem;
	line-height: 1rem;
	font-weight: 400;
    padding-top: var(--space-sm);
    padding-bottom: var(--space-xxs);
	&__title {
		margin-bottom: var(--space-md);
	}
}

.container {
	max-width: 1230px;
	padding-right: 30px;
	padding-left: 30px;
	margin-left: auto;
	margin-right: auto;
}

/* Для добавления фона на страницу? расширяет фон на всю высоту */
.page-bg{
	min-height: calc(100vh - 116px);
    padding-bottom: var(--space-xs);
	&_white{
		background: $white;
	}
}

.marker-text {
	font-size: 80%;
	font-weight: 500;
	&_pointer {
		cursor: pointer;
	}
	&_active {
		color: $primary;
	}
	&_disabled {
		color:  rgba(0, 0, 0, 0.3);
		cursor: auto;
	}
}


.tooltip{
	& > &-inner{
		/* Добавлена минимальная ширина контейнера tooltip */
		min-width: 30px;
	}
} 

.navigation-menu {
	padding: 0;
	margin: 0;

	&__item {
		display: inline-block;
		margin-right:  var(--space-md, 1rem);

		&:last-child {
			margin-right: 0;
		}
	}
	&__link {
		color: var(--color-black, black);
		line-height: 26px;

		&_active {
			color: var(--color-black, black);
			text-decoration: none;
		}
		
		&:hover {
			color: var(--color-black, black);
			opacity: 0.3;
			text-decoration: none;
		}
	}
}

.widget-demo-container{
	background: rgba(76, 126, 255, 0.18);
	display: flex;
	align-items: center;
	justify-content: center;
}




/* добавлена стили из style.scss всего проекта их*/
html {
    min-height: 100%;
    background: #f6f7f7;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.radio-style{
  display: inline-block;
  &__input{
      display: none;

  }
  &_orange{
      .radio-style__label{
          position: relative;
          padding-left: 24px;
          display: inline-block;
          min-height: 16px;
          cursor: pointer;
          &:after{
              -webkit-border-radius: 8px;
              -moz-border-radius: 8px;
              border-radius: 8px;
              width: 16px;
              height: 16px;
              content: '';
              background-color: #f6f6f8;
              position: absolute;
              left: 0;
              top: 50%;
              margin-top: -8px;
          }
          &:hover{
              &:after{
                  border:2px solid #ffb400;
              }
          }
      }
      .radio-style__input{
          &:checked{
              &+.radio-style__label{
                  &:after{
                      border:5px solid #ffb400 !important;
                  }
              }
          }
      }

  }

}
body {
    line-height: 21px;
    font-family: 'Roboto', sans-serif;
    background: #f6f7f7;
    font-size: 14px;
}   
.calendar-dropdown-view__button.btn.dropdown-toggle{
    max-width: 100%;
}

.widget {
    &__title {
        border-bottom: 1px solid var(--color-border-gray);
        padding-bottom: 11px;
    }
    &__content {
        padding-top: var(--space-xs);
    }
    &_bordered {
        border: 1px solid var(--color-border-gray);
        border-radius: 3px;
    }
}

.btn-group .btn.btn-default {
    color: #8a8d90;
    padding: 9px 8px;
    box-shadow: none;
    border: 1px solid #ccc;
    height: 32px;

}

.btn-group .btn.btn-default.active {
    background: #239cfd;
    color: white;
    border: 1px solid #239cfd;
}

.file-uploader-dropzone{
    background: #fff;
}


.custom-dropdown-menu {
    border: 1px solid #ebebeb;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
    & .arrow {
        display: none;
    }
    & .popover-body {
        padding: 0;
    }
}
.custom-list {
    padding: 0; 
    margin: 0;

    &__item {
        padding: 8px 14px; 
        min-width: 150px;
        cursor: pointer;
    }
    &__item:hover {
        background-color: rgba(69, 114, 229, 0.1);
    }
}

.hi {
    border: solid 4px red;
}

.hi-green {
    border: solid 4px green;
}

.hi-blue {
    border: solid 4px blue;
}

.hi-golden {
    border: solid 4px goldenrod;
}

.hi-yellow {
    border: solid 4px yellow;
}

.hi-black {
    border: solid 4px black;
}

// Стили для графиков на странице событий: "Метрики", "Периоды", "Объекты"
.chart {
    &__dot {
        height: 8px;
        width: 8px;
        background-color: $colorLegendGrey;
        border-radius: 50%;
        display: inline-block;
        margin: 0 5px 0 0;
    }

    &__legend {
        border: 1px solid #ebebeb;
        border-radius: 30px;
        padding: 5px 10px;
        color: $colorLegendGrey;
        cursor: default;

        &-text {
            font-weight: 500;
            font-size: 12px;
        }

        &-enabled {
            cursor: pointer;
        }

        &-visible {
            color: $colorLegendBlack;
        }

        &__avg {
            margin-left: 5px;
        }
    }

    &__tooltip {
        background-color: white;
        border-radius: 5px;
        padding: 10px;
        box-shadow: 3px 4px 4px 3px rgba(166, 166, 166, 0.30);    
    
        &__header {
            display: flex;
            justify-content: space-between;
            padding: 5px;
            border-bottom: 1px solid #EBEBEB;
            font-weight: bold;
    
            &-right-side {
                padding-left: 40px !important;
            }
        }
    
        &__body {
            padding-top: 5px;
            
            &-right-side {
                padding-left: 40px !important;
            }
    
            table {
                width: 100%;
                td {
                    padding: 0 5px;
    
                    &:first-child {
                        text-align: left;
                    }
    
                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    
        &__measure {        
            color: $colorLegendGrey !important;
        }
    }
}

.owl-nav {
    .owl-next {
        position: absolute;
        top: 0;
        height: 100%;
        right: -16px;
        width: 100px;
        margin: 0 !important;
        background: linear-gradient(270deg,#fff,#fff 38.67%,hsla(0,0%,100%,0)) !important;
    }
    & .owl-next.disabled {
        display: none;
    }

    .owl-prev {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100px;
        left: -16px;
        margin: 0 !important;
        background: #fff !important;
        background: linear-gradient(-270deg,#fff,#fff 38.67%,hsla(0,0%,100%,0)) !important;
        
    }
    & .owl-prev.disabled {
        display: none;
    }
}
.horizontal-scroll {
    overflow: auto hidden;
    display: block;
    box-sizing: border-box;
}
