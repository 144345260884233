//Button primary colors

    //default
        $button-primary-default-color: $primary; // Основной цвет кнопки
        $button-style-primary-bg-color: $button-primary-default-color; // Основной цвет фона кнопки
        $button-style-primary-color: $white; // Основной цвет текста кнопки
        $button-style-primary-border-color: $button-primary-default-color; // Основной цвет обводки 

    //hover
        $button-style-primary-bg-color-hover: transparentize($button-primary-default-color,.6); // Цвет фона при наведени
        $button-style-primary-border-color-hover: transparentize($button-primary-default-color,.6); // Цвет обводки при наведени
        $button-style-primary-color-hover:  $button-style-primary-color; // Цвет текста при наведении
    //active
        $button-style-primary-bg-color-active: $button-primary-default-color; // Цвет фона при active
        $button-style-primary-border-color-active: $button-primary-default-color; // Цвет обводки при active
        $button-style-primary-color-active:  $button-style-primary-color; // Цвет текста при active


//Button outline-primary colors
    //default
        $button-style-outline-primary-bg-color: transparent; // Основной цвет фона кнопки
        $button-style-outline-primary-color: $black; // Основной цвет текста кнопки
        $button-style-outline-primary-border-color: $button-primary-default-color; // Основной цвет обводки 

    //hover
        $button-style-outline-primary-bg-color-hover: $button-primary-default-color; // Цвет фона при наведени
        $button-style-outline-primary-border-color-hover: $button-primary-default-color; // Цвет обводки при наведени
        $button-style-outline-primary-color-hover: $white; // Цвет текста при наведении
    //active
        $button-style-outline-primary-bg-color-active: $button-primary-default-color; // Цвет фона при active
        $button-style-outline-primary-border-color-active: $button-primary-default-color; // Цвет обводки при active
        $button-style-outline-primary-color-active: $white; // Цвет текста при active           

//
    //default
        $button-style-bordered-primary-bg-color: $white;
        $button-style-bordered-primary-border-color: transparentize($button-primary-default-color,.8);
        $button-style-bordered-primary-color: $black;

    //hover 
        $button-style-bordered-primary-border-color-active:  $button-primary-default-color;