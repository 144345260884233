//Button warning colors

    //default
        $button-warning-default-bg-color: $warning; // Основной цвет кнопки
        $button-style-warning-bg-color: $button-warning-default-bg-color; //Основной цвет фона кнопки
        $button-style-warning-color: $black; // Основной цвет текста кнопки
        $button-style-warning-border-color: $button-warning-default-bg-color; // Основной цвет обводки 

    //hover
        $button-style-warning-bg-color-hover: transparentize($button-warning-default-bg-color,.6); // Цвет фона при наведени
        $button-style-warning-border-color-hover: transparentize($button-warning-default-bg-color,.6); // Цвет обводки при наведени
        $button-style-warning-color-hover:  $button-style-warning-color; // Цвет текста при наведении
    //active
        $button-style-warning-bg-color-active:  $button-warning-default-bg-color; // Цвет фона при active
        $button-style-warning-border-color-active:  $button-warning-default-bg-color; // Цвет обводки при active
        $button-style-warning-color-active:  $button-style-warning-color; // Цвет текста при active


//Button outline-warning colors
    //default
        $button-style-outline-warning-bg-color: transparent; // Основной цвет фона кнопки
        $button-style-outline-warning-color: $black; // Основной цвет текста кнопки
        $button-style-outline-warning-border-color: $button-warning-default-bg-color; // Основной цвет обводки 

    //hover
        $button-style-outline-warning-bg-color-hover: $button-warning-default-bg-color; // Цвет фона при наведени
        $button-style-outline-warning-border-color-hover: $button-warning-default-bg-color; // Цвет обводки при наведени
        $button-style-outline-warning-color-hover: $white; // Цвет текста при наведении
    //active
        $button-style-outline-warning-bg-color-active: $button-warning-default-bg-color; // Цвет фона при active
        $button-style-outline-warning-border-color-active: $button-warning-default-bg-color; // Цвет обводки при active
        $button-style-outline-warning-color-active: $white; // Цвет текста при active