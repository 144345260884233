.col {
    &-h {
        &_1 {
            height: calc(var(--h-grid-size-point) * var(--h-grid-size-scale));
        }   
        &_2 {
            height: calc(var(--h-grid-size-point) * (var(--h-grid-size-scale) * 2));
        }
        &_3 {
            height: calc(var(--h-grid-size-point) * (var(--h-grid-size-scale) * 3));
        }   
        &_4 {
            height: calc(var(--h-grid-size-point) * (var(--h-grid-size-scale) * 4));
        }
        &_5 {
            height: calc(var(--h-grid-size-point) * (var(--h-grid-size-scale) * 5));
        }   
        &_6 {
            height: calc(var(--h-grid-size-point) * (var(--h-grid-size-scale) * 6));
        }
        &_7 {
            height: calc(var(--h-grid-size-point) * (var(--h-grid-size-scale) * 7));
        }   
        &_8 {
            height: calc(var(--h-grid-size-point) * (var(--h-grid-size-scale) * 8));
        }
        &_9 {
            height: calc(var(--h-grid-size-point) * (var(--h-grid-size-scale) * 9));
        }   
        &_10 {
            height: calc(var(--h-grid-size-point) * (var(--h-grid-size-scale) * 10));
        }
        &_11 {
            height: calc(var(--h-grid-size-point) * (var(--h-grid-size-scale) * 11));
        }   
        &_12 {
            height: calc(var(--h-grid-size-point) * (var(--h-grid-size-scale) * 12));
        }
    } 
}