  /*firefox*/
.atoms_slider {

    &::-moz-focus-outer {
        border: 0;
    }

    &::-moz-range-track {
        background: var(--color-primary-lighter ); /*trackColor*/
        height: 2px; /*trackHeight*/
        /*background isn't animatable, so can't use transition*/
    }

    &::-moz-range-thumb {
        background: #fff; /*thumbColor*/
        width: calc(var(--slider-thumb-size) - 8px); /*thumbHeight*/
        height: calc(var(--slider-thumb-size) - 8px); /*thumbHeight*/
        border-radius: 50%;
        cursor: pointer;
        border: 4px solid var(--color-primary); /*border-width = thumbBorderWidth, border-color should match the background*/
    }

    &::-moz-range-progress {
        background:  var(--color-primary); /*thumbColor*/
        border-radius: 4px; /*trackHeight*/
        height: 2px; /*trackHeight*/
    }

    &:hover {
        &::-moz-range-track {
            background: var(--color-primary-lighter ); /*activeTrackColor*/
        }

        &::-moz-range-progress {
            background: var(--color-primary); /*activeThumbColor*/
        }
    }

    &:focus {
        &::-moz-range-track {
            background: var(--color-primary-lighter ); /*activeTrackColor*/
        }

        &::-moz-range-progress {
            background: var(--color-primary); /*activeThumbColor*/
        }
    }
}