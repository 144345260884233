// Border Radius
:root {
  --radius: 4px;
  --radius-xs: calc(var(--radius)/2); //2px
  --radius-sm: var(--radius); //4px
  --radius-md: calc(var(--radius)*2); //8px
  --radius-lg: calc(var(--radius)*3); //12px
  --radius-xl: calc(var(--radius)*5); //20px

  --radius-circle: 50%;
}
// --------------------------------

  .radius-50\% { border-radius: 50%; }
  .radius-full { border-radius: 50em; }

// --------------------------------

.border-radius {
  &--xs {
      border-radius: var(--radius-xs);
  }
  &--sm {
      border-radius: var(--radius);
  }
  &--md {
      border-radius: var(--radius-md);
  }
  &--lg {
      border-radius: var(--radius-lg);
  }
  &--xl {
      border-radius: var(--radius-xl);
  }
  &--unset {
      border-radius: 0;
  }
  &--circle {
      border-radius: var(--radius-circle);
  }
}
.border-radius {
  &--bottom-left--unset {
      border-bottom-left-radius: 0;
  }
  &--bottom-right--unset {
      border-bottom-right-radius: 0;
  }
  &--top-left--unset {
      border-top-left-radius: 0;
  }
  &--top-right--unset {
      border-top-right-radius: 0;
  }
}