// --------------------------------

// (START) Global Editor code https://codyhouse.co/ds/globals/spacing

// --------------------------------

@supports(--css: variables) {
  :root {
    --space-unit:  1em;
    --space-xxxxs: calc(0.25 * var(--space-unit));
    --space-xxxs:  calc(0.5 * var(--space-unit));
    --space-xxs:   calc(0.75 * var(--space-unit));
    --space-xs:    calc(1 * var(--space-unit));
    --space-sm:    calc(1.25 * var(--space-unit));
    --space-md:    calc(1.75 * var(--space-unit));
    --space-lg:    calc(2.25 * var(--space-unit));
    --space-xl:    calc(2.75 * var(--space-unit));
    --space-xxl:   calc(3.25 * var(--space-unit));
    --space-xxxl:  calc(5 * var(--space-unit));
    --space-xxxxl: calc(7 * var(--space-unit));
    --component-padding: var(--space-md);
    @include breakpoint(md) {
      --space-unit:  1.25em;
    }
  }
}

// --------------------------------

// (END) Global Editor Code

// --------------------------------