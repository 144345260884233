 /*chrome and opera*/
 .atoms_slider {

    &::-webkit-slider-runnable-track {
        background: var(--color-primary-lighter); /*trackColor*/
        height: 2px; /*trackHeight*/
        transition: 0.3s;
        border-radius:50em;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        background: #fff; /*thumbColor*/
        width: var(--slider-thumb-size); /* thumbHeight + (2 x thumbBorderWidth)*/
        height: var(--slider-thumb-size); /* thumbHeight + (2 x thumbBorderWidth)*/
        border-radius: 50%;
        margin-top: -7px; /* -[thumbHeight + (2 x thumbBorderWidth) - trackHeight]/2*/
        cursor: pointer;
        border: 4px solid  var(--color-primary); /*border-width should be equal to thumbBorderWidth if you want same border width across all browsers and border-color should match the background*/
        transition: 0.3s;
        
    }

    &:hover {
        &::-webkit-slider-runnable-track {
            background: var(--color-primary-lighter ); /*activeTrackColor*/
        }
    }
    &:focus {
        &::-webkit-slider-runnable-track {
            background: var(--color-primary-lighter ); /*activeTrackColor*/
        }
    }
}