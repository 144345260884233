//Button secondary colors

    //default
        $button-secondary-default-color: $secondary; // Основной цвет кнопки
        $button-style-secondary-bg-color: $button-secondary-default-color; //Основной цвет фона кнопки
        $button-style-secondary-color: $black; // Основной цвет текста кнопки
        $button-style-secondary-border-color: $button-secondary-default-color; // Основной цвет обводки 

    //hover
        
        $button-style-secondary-bg-color-hover: transparentize($button-secondary-default-color,.6); // Цвет фона при наведени
        $button-style-secondary-border-color-hover: transparentize($button-secondary-default-color,.6); // Цвет обводки при наведени
        $button-style-secondary-color-hover:  $button-style-secondary-color; // Цвет текста при наведении
    //active
        $button-style-secondary-bg-color-active: $button-secondary-default-color; // Цвет фона при active
        $button-style-secondary-border-color-active: $button-secondary-default-color; // Цвет обводки при active
        $button-style-secondary-color-active:  $button-style-secondary-color; // Цвет текста при active


//Button outline-secondary colors
    //default
        $button-style-outline-secondary-bg-color: transparent; // Основной цвет фона кнопки
        $button-style-outline-secondary-color: $primary; // Основной цвет текста кнопки
        $button-style-outline-secondary-border-color: $button-style-outline-secondary-bg-color; // Основной цвет обводки 

    //hover
        $button-style-outline-secondary-bg-color-hover: rgba(69, 114, 229, 0.15); // Цвет фона при наведени
        $button-style-outline-secondary-border-color-hover: rgba(69, 114, 229, 0.15);; // Цвет обводки при наведени
        $button-style-outline-secondary-color-hover: $primary; // Цвет текста при наведении
    //active
        $button-style-outline-secondary-bg-color-active: rgba(69, 114, 229, 0.15); // Цвет фона при active
        $button-style-outline-secondary-border-color-active: rgba(69, 114, 229, 0.15); // Цвет обводки при active
        $button-style-outline-secondary-color-active: $primary;  // Цвет текста при active