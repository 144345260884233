

.ng{
	&-select {
		min-height: 36px;
		font-size: 14px;
		color: $font-default-color;
		border: 1px solid transparentize($primary,.7);
		border-radius: 5px;
		cursor: pointer;
		&:hover {
			border-color: $primary; 
			background-color: transparentize($primary, 0.9);
		} 
		
		&.ng-select-single &-container {
			min-height: 34px;
			height: 34px;
			min-width: 250px;
			@media (max-width: 520px){
				min-width: 160px;
				max-width: 160px;
			}

		}
		&-multiple {
			height: auto;
			.ng-select-container {
				height: auto;
			}
		}
		&.ng-select-multiple {
			.ng-select-container {
				height: auto;
			}
		}
		& &-container {
			min-height: 34px;
			height: 34px;
			border-radius: 4px;
			background-color:  #fff;
			border: none;
			& .ng-value-container .ng-placeholder {
				color: $font-default-color;
			}
		}
		&.ng-select-opened {
			& > &-container {
				background-color:  #fff;
				border-radius: 5;
			}
			&.ng-select-bottom {
				& >.ng-select-container {
					border-bottom-right-radius: 5px;
					border-bottom-left-radius: 5px;
				}
			}
		}
		&.ng-select-focused:not(&-opened) > &-container {
			box-shadow: none;
		}
		&_lg {
			&.ng-select {
				& .ng-select-container, &.ng-select-single .ng-select-container{
					height: auto;
					padding: 13px 0 12px;
					line-height: 16px;
				}
				&.ng-select-single .ng-select-container .ng-value-container .ng-input{
					top: 14px;
				}
				& .ng-clear-wrapper{
					height: 16px;
				}
				& .ng-input > input{
					height: 14px;
				}
			}
			
		}
	}
	&-dropdown-panel {
		border: none;
		box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.06);
		border-radius: 5px;
		& &-items {
			margin-bottom: 0;
			& .ng-option {
				line-height: 20px;
				padding: 11px 12px 12px;
				&.ng-option-selected {
					background-color: transparentize($primary, 0.9);
					& .ng-option-label {
						font-weight: normal;
					}
				}
			}
		}
		
		&.ng-select-bottom {
			margin-top: 1px;
			padding-top: 5px;
			border-top: none;

			& .ng-dropdown-panel-items {
				& .ng-option {
					&:last-child {
						border-bottom-right-radius: 5px;
						border-bottom-left-radius: 5px;
					}
				}
			} 
		}
	}
	&-option {
		height: auto;

		&:hover{
			background-color: transparentize($primary, 0.9) !important;
		}
		&-marked {
			background-color: transparentize($primary, 0.9) !important;
		}
	}
}