@mixin input-outline($bgcolor) {
    box-shadow: none;
    border-color: $bgcolor;
}

.form-control{
	font-size: 14px;
	font-family: $font-stack;
	border: solid 1px transparentize($primary,.7);
	color: $font-default-color;
	border-radius: 4px;
	line-height: 1.125rem;
	box-sizing: border-box;

	&.is-valid{
		@include input-outline($color-success);
		&:focus{
			@include input-outline($color-brand);
		}
	}

	&.is-invalid{
		@include input-outline($color-danger);
		&:focus{
			@include input-outline($color-brand);
		}
	}
	&:invalid{
		&:focus{
			@include input-outline($color-brand);
		}
		@include input-outline($color-danger);
	}
	&:focus{
		@include input-outline($color-brand);
	}

	&--full-width {
		width: 100%;
	}

}

textarea.form-control{
	height: auto;
}

.input-group-append{
	.btn{
		height: 32px;
		min-height: 32px;
		padding-top: 2px;
	}
}