@import './crumbs_core-shell-styles/assets/scss/common';
@import './lib/esm/index.css';

*,
:after,
:before {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

body {
    font-weight: 400;
    text-align: left;
    text-size-adjust: 100%;
    -webkit-font-smoothing: auto;
}

.highcharts-tooltip {
    table {
        th {
            padding: var(--space-xxxs);
            padding-top: 0;
            border-bottom: 1px solid var(--color-secondary);
        }
        td {
            padding: var(--space-xxxs);
            padding-bottom: 0;
        }
    }
}
