.btn{
    &-link{
        @include button-state-disabled(false) {
            @include button-state-hover {
                color: $button-style-link-color-active;
                text-decoration: none;
                opacity: 0.6;
            }
            @include button-state-focus {
                text-decoration: none;
            }
            @include button-state-focus-active {
                color: $button-style-link-color-active;
                text-decoration: none;
                opacity: 1;
            }
        }
    }
}