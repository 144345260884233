@import 'primary';
@import 'secondary';
@import 'success';
@import 'danger';
@import 'warning';
@import 'info';
@import 'link';


.btn {
    &:focus,
	&.focus {
		outline: 0;
		@include box-shadow($button-style-shadow);
    }
}
