

$font-default-color: #212529; 
$font-dark-color: #000;
$font-lighter-color: #abafb3;


$blue:    #4572E6;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #ff0000;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #95dfce;
$cyan:    #17a2b8;
$black:   #000000;
$white:   #ffffff;
$gray:    #e8ecf0;
$color-brand: $blue;
$color-secondary: #f1f5f8;
$color-danger: #dc3545;
$color-success: #3ca91a;


$primary:       $blue;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$secondary:     $gray;

:root {
    --color-danger:        #ff0000;
    --color-border-gray: #E0E0E0;
}