@import "webkit";
@import "mozilla";
@import "microsoft";

.atoms_slider {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 16px;
    -webkit-appearance: none; /*remove the line*/
    outline: none;
    /*background-color:cyan;*/
    border: 0; /*for firefox on android*/
    padding: 0; /*for IE*/
    margin: 0;
    filter: var(--color-primary);
    background:0 0;
    cursor: pointer;
    &:focus {
        outline: none;
    }
       
    &__ruler {
        position: relative;
        width: calc(100% - var(--slider-thumb-size));
        margin-left: calc(var(--slider-thumb-size)/2);
        &-item {
            font-size: 14px;
            position: absolute;
            transform: translate(-50%,0);
            /**TODO Добавить в набор палитры оттенки белого, черного, серого цвета и заменить данный цвет */
            color: #999999;
            white-space: nowrap;
            &:before {
                top: -18px;
                left: 50%;
                position: absolute;
                width: 1px;
                height: 8px;
                background-color: var(--color-primary-lighter);
                content: '';
            }
            &:first-child {
                &:before {
                    left: calc(50% - var(--slider-thumb-size)/2);
                }
            }
            &:last-child {
                &:before {
                    left: calc(50% + var(--slider-thumb-size)/2);
                }
            }
            &--hide-point {
                &:before {
                    display: none;
                }
            }
            &--active-point {
                &:before {
                    display: none;
                }
                /**TODO Добавить в набор палитры оттенки белого, черного, серого цвета и заменить данный цвет */
                color: #000000;
            }
        }
    }
}
