.background {
    /* Основная палитра по типам */
    &-primary {
        background-color: var(--color-primary);
        &-darker {background-color: var(--color-primary-darker);}
        &-dark {background-color: var(--color-primary-dark);}
        &-light {background-color: var(--color-primary-light);}
        &-lighter {background-color: var(--color-primary-lighter);}
    }
    &-accent {
        background-color: var(--color-accent);
        &-darker {background-color: var(--color-accent-darker);}
        &-dark {background-color: var(--color-accent-dark);}
        &-light {background-color: var(--color-accent-light);}
        &-lighter {background-color: var(--color-accent-lighter);}
    }
    &-secondary {
        background-color: var(--color-secondary);
        &-darker {background-color: var(--color-secondary-darker);}
        &-dark {background-color: var(--color-secondary-dark);}
        &-light {background-color: var(--color-secondary-light);}
        &-lighter {background-color: var(--color-secondary-lighter);}
    }
    &-warning {
        background-color: var(--color-warning);
        &-darker {background-color: var(--color-warning-darker);}
        &-dark {background-color: var(--color-warning-dark);}
        &-light {background-color: var(--color-warning-light);}
        &-lighter {background-color: var(--color-warning-lighter);}
    }
    /* Для поддержки уже используемого класса background-danger */
    &-danger {
        background-color: var(--color-error);
        &-darker {background-color: var(--color-error-darker);}
        &-dark {background-color: var(--color-error-dark);}
        &-light {background-color: var(--color-error-light);}
        &-lighter {background-color: var(--color-error-lighter);}
    }
    &-error {
        background-color: var(--color-error);
        &-darker {background-color: var(--color-error-darker);}
        &-dark {background-color: var(--color-error-dark);}
        &-light {background-color: var(--color-error-light);}
        &-lighter {background-color: var(--color-error-lighter);}
    }
    &-success {
        background-color: var(--color-success);
        &-darker {background-color: var(--color-success-darker);}
        &-dark {background-color: var(--color-success-dark);}
        &-light {background-color: var(--color-success-light);}
        &-lighter {background-color: var(--color-success-lighter);}
    }
    
    &-info {
        background-color: var(--color-info);
        &-darker {background-color: var(--color-info-darker);}
        &-dark {background-color: var(--color-info-dark);}
        &-light {background-color: var(--color-info-light);}
        &-lighter {background-color: var(--color-info-lighter);}
    }
    
    /* Основная палитра по цветам */
    &-blue {
        background-color: var(--color-blue);
        &-darker {background-color: var(--color-blue-darker);}
        &-dark {background-color: var(--color-blue-dark);}
        &-light {background-color: var(--color-blue-light);}
        &-lighter {background-color: var(--color-blue-lighter);}
    }

    &-orange {
        background-color: var(--color-orange);
        &-darker {background-color: var(--color-orange-darker);}
        &-dark {background-color: var(--color-orange-dark);}
        &-light {background-color: var(--color-orange-light);}
        &-lighter {background-color: var(--color-orange-lighter);}
    }

    &-turquoise {
        background-color: var(--color-turquoise);
        &-darker {background-color: var(--color-turquoise-darker);}
        &-dark {background-color: var(--color-turquoise-dark);}
        &-light {background-color: var(--color-turquoise-light);}
        &-lighter {background-color: var(--color-turquoise-lighter);}
    }

    &-yellow {
        background-color: var(--color-yellow);
        &-darker {background-color: var(--color-yellow-darker);}
        &-dark {background-color: var(--color-yellow-dark);}
        &-light {background-color: var(--color-yellow-light);}
        &-lighter {background-color: var(--color-yellow-lighter);}
    }

    &-purple {
        background-color: var(--color-purple);
        &-darker {background-color: var(--color-purple-darker);}
        &-dark {background-color: var(--color-purple-dark);}
        &-light {background-color: var(--color-purple-light);}
        &-lighter {background-color: var(--color-purple-lighter);}
    }

    &-pink {
        background-color: var(--color-pink);
        &-darker {background-color: var(--color-pink-darker);}
        &-dark {background-color: var(--color-pink-dark);}
        &-light {background-color: var(--color-pink-light);}
        &-lighter {background-color: var(--color-pink-lighter);}
    }

    &-gray {
        background-color: var(--color-gray);
        &-darker {background-color: var(--color-gray-darker);}
        &-dark {background-color: var(--color-gray-dark);}
        &-light {background-color: var(--color-gray-light);}
        &-lighter {background-color: var(--color-gray-lighter);}
    }

    &-black {
        background-color: var(--color-black);
    }

    &-white {
        background-color: var(--color-white);
    }
}