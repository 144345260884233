.molecules-collapse {
    //Структура
    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    &__icon {
        display: flex;
        transition: 0.1s transform;
        &--right {
            margin-left: auto;
            justify-self: flex-end;
        }
    }

    //Модификаторы
    &--open {
        & .molecules-collapse__icon atoms_svg-icon-form {
           transform: rotate(90deg); 
        }
    }
}
