$sidebar-width-extended: 15.5rem;
$sidebar-width-compact: 3.6rem;
$sidebar-background-color: #fdfcfb;

.sidebar-list {
    height: auto;
    width: $sidebar-width-extended;

    &--compact {
        width: $sidebar-width-compact;
    }
}

.navigation-item {
    flex-shrink: 1;
    overflow: hidden;
    display: flex;
    align-items: center;

    &__text {
        flex-shrink: 1;
        white-space: normal;
        user-select: none;
    }

    &__beta-marker {
        flex-shrink: 0;
    }
}

.crumbs_sidebar-sidebar-form {
    overflow: hidden;
    // position: fixed;
    flex-shrink: 0;
    display: flex;
    box-sizing: content-box;
    flex-direction: column;
    width: $sidebar-width-extended;
    height: 100%;
    background-color: $sidebar-background-color;
    border-right: 1px solid #ebebeb;
    z-index: 2;

    &--left {
        top: 0;
        left: 0;
    }

    &--compact {
        width: $sidebar-width-compact;
    }
}

.crumbs_sidebar-compact-switcher-form {
    color: #000;
    display: flex;
    font-size: 16px;
    min-height: 24px;
}

.crumbs_sidebar-compact-switcher {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: inherit;
    overflow: hidden;
    padding: 0 var(--space-sm);
    width: $sidebar-width-extended;
    &--compact {
        justify-content: center;
        padding: 0;
        width: $sidebar-width-compact;
    }
}

.crumbs_sidebar-compact-switcher__stub {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 16px;
}

.crumbs_sidebar-compact-switcher__content {
    display: flex;
    flex-shrink: 1;
    height: inherit;
    margin-left: var(--space-xxs);
    overflow: hidden;
}

.navigation-item {
    align-items: center;
    display: flex;
    flex-shrink: 1;
    overflow: hidden;
}
.navigation-item__text {
    flex-shrink: 1;
    user-select: none;
    white-space: normal;
    align-items: center;
    display: flex;
}

.crumbs_sidebar-menu-form {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    width: inherit;
    > div {
        width: 100%;
    }
}

.ss-wrapper {
    overflow: hidden;
    width: 100%;
    z-index: 1;
    float: left;
}

.ss-content,
.ss-wrapper {
    height: 100%;
    position: relative;
}

.ss-content {
    width: calc(100% + 18px);
    padding: 0;
    overflow: auto;
    box-sizing: border-box;
    overflow-x: hidden;
}

.collapse-header {
    font-size: 12px;
    min-height: 24px;
    text-transform: uppercase;
}

.crumbs_sidebar-link-form {
    display: block;

    &:hover {
        &:before {
            background: var(--color-primary);
            border-radius: 0 18px 18px 0;
            bottom: 0;
            content: '';
            height: 100%;
            opacity: 0.15;
            position: absolute;
            right: 0;
            width: 100%;
        }
    }

    &--active {
        font-weight: 500;
        &:before {
            background: var(--color-primary);
            border-radius: 0 18px 18px 0;
            bottom: 0;
            content: '';
            height: 100%;
            opacity: 0.15;
            position: absolute;
            right: 0;
            width: 100%;
        }
    }
}

.sidebar-link {
    text-decoration: none;
}

.sidebar-list-item {
    background-color: transparent;
    position: relative;
    width: inherit;
}

.sidebar-divider {
    margin: var(--space-xxs) 0;
    padding: 0 var(--space-sm);
    display: flex;
    width: $sidebar-width-extended;

    &--compact {
        width: $sidebar-width-compact;
    }

    &:before {
        border-bottom: 1px solid #ebebeb;
        content: '';
        display: flex;
        height: 1px;
        width: 100%;
    }
}
