.space {

    &_outer{
        &_xxxxs {
            margin: var(--space-xxxxs);
        }
        &_xxxs {
            margin: var(--space-xxxs);
        }
        &_xxs {
            margin: var(--space-xxs);
        }
        &_xs {
            margin: var(--space-xs);
        } 
        &_sm {
            margin: var(--space-sm);
        }
        &_md {
            margin: var(--space-md);
        }
        &_lg {
            margin: var(--space-lg);
        }
        &_xl {
            margin: var(--space-xl);
        }
        &_xxl {
            margin: var(--space-xxl);
        }
        &_xxxl {
            margin: var(--space-xxxl);
        }
        &_xxxxl {
            margin: var(--space-xxxxl);
        }

        &_b {
            &_xxxxs {
                margin-bottom: var(--space-xxxxs);
            }
            &_xxxs {
                margin-bottom: var(--space-xxxs);
            }
            &_xxs {
                margin-bottom: var(--space-xxs);
            }
            &_xs {
                margin-bottom: var(--space-xs);
            } 
            &_sm {
                margin-bottom: var(--space-sm);
            }
            &_md {
                margin-bottom: var(--space-md);
            }
            &_lg {
                margin-bottom: var(--space-lg);
            }
            &_xl {
                margin-bottom: var(--space-xl);
            }
            &_xxl {
                margin-bottom: var(--space-xxl);
            }
            &_xxxl {
                margin-bottom: var(--space-xxxl);
            }
            &_xxxxl {
                margin-bottom: var(--space-xxxxl);
            }
        }
        &_t {
            &_xxxxs {
                margin-top: var(--space-xxxxs);
            }
            &_xxxs {
                margin-top: var(--space-xxxs);
            }
            &_xxs {
                margin-top: var(--space-xxs);
            }
            &_xs {
                margin-top: var(--space-xs);
            } 
            &_sm {
                margin-top: var(--space-sm);
            }
            &_md {
                margin-top: var(--space-md);
            }
            &_lg {
                margin-top: var(--space-lg);
            }
            &_xl {
                margin-top: var(--space-xl);
            }
            &_xxl {
                margin-top: var(--space-xxl);
            }
            &_xxxl {
                margin-top: var(--space-xxxl);
            }
            &_xxxxl {
                margin-top: var(--space-xxxxl);
            }
        }
        &_r {
            &_xxxxs {
                margin-right: var(--space-xxxxs);
            }
            &_xxxs {
                margin-right: var(--space-xxxs);
            }
            &_xxs {
                margin-right: var(--space-xxs);
            }
            &_xs {
                margin-right: var(--space-xs);
            } 
            &_sm {
                margin-right: var(--space-sm);
            }
            &_md {
                margin-right: var(--space-md);
            }
            &_lg {
                margin-right: var(--space-lg);
            }
            &_xl {
                margin-right: var(--space-xl);
            }
            &_xxl {
                margin-right: var(--space-xxl);
            }
            &_xxxl {
                margin-right: var(--space-xxxl);
            }
            &_xxxxl {
                margin-right: var(--space-xxxl);
            }
        }
        &_l {
            &_xxxxs {
                margin-left: var(--space-xxxxs);
            }
            &_xxxs {
                margin-left: var(--space-xxxs);
            }
            &_xxs {
                margin-left: var(--space-xxs);
            }
            &_xs {
                margin-left: var(--space-xs);
            } 
            &_sm {
                margin-left: var(--space-sm);
            }
            &_md {
                margin-left: var(--space-md);
            }
            &_lg {
                margin-left: var(--space-lg);
            }
            &_xl {
                margin-left: var(--space-xl);
            }
            &_xxl {
                margin-left: var(--space-xxl);
            }
            &_xxxl {
                margin-left: var(--space-xxxl);
            }
        }
        &_x {
            &_xxxxs {
                margin-right: var(--space-xxxxs);
                margin-left: var(--space-xxxxs);
            }
            &_xxxs {
                margin-right: var(--space-xxxs);
                margin-left: var(--space-xxxs);
            }
            &_xxs {
                margin-right: var(--space-xxs);
                margin-left: var(--space-xxs);
            }
            &_xs {
                margin-right: var(--space-xs);
                margin-left: var(--space-xs);
            } 
            &_sm {
                margin-right: var(--space-sm);
                margin-left: var(--space-sm);
            }
            &_md {
                margin-right: var(--space-md);
                margin-left: var(--space-md);
            }
            &_lg {
                margin-right: var(--space-lg);
                margin-left: var(--space-lg);
            }
            &_xl {
                margin-right: var(--space-xl);
                margin-left: var(--space-xl);
            }
            &_xxl {
                margin-right: var(--space-xxl);
                margin-left: var(--space-xxl);
            }
            &_xxxl {
                margin-right: var(--space-xxxl);
                margin-left: var(--space-xxxl);
            }
            &_xxxxl {
                margin-right: var(--space-xxxxl);
                margin-left: var(--space-xxxxl);
            }
        }
        &_y {
            &_xxxxs {
                margin-top: var(--space-xxxxs);
                margin-bottom: var(--space-xxxxs);
            }
            &_xxxs {
                margin-top: var(--space-xxxs);
                margin-bottom: var(--space-xxxs);
            }
            &_xxs {
                margin-top: var(--space-xxs);
                margin-bottom: var(--space-xxs);
            }
            &_xs {
                margin-top: var(--space-xs);
                margin-bottom: var(--space-xs);
            } 
            &_sm {
                margin-top: var(--space-sm);
                margin-bottom: var(--space-sm);
            }
            &_md {
                margin-top: var(--space-md);
                margin-bottom: var(--space-md);
            }
            &_lg {
                margin-top: var(--space-lg);
                margin-bottom: var(--space-lg);
            }
            &_xl {
                margin-top: var(--space-xl);
                margin-bottom: var(--space-xl);
            }
            &_xxl {
                margin-top: var(--space-xxl);
                margin-bottom: var(--space-xxl);
            }
            &_xxxl {
                margin-top: var(--space-xxxl);
                margin-bottom: var(--space-xxxl);
            }
            &_xxxxl {
                margin-top: var(--space-xxxxl);
                margin-bottom: var(--space-xxxxl);
            }
        }
    }

    &_inner {
        &_xxxxs {
            padding: var(--space-xxxxs);
        }
        &_xxxs {
            padding: var(--space-xxxs);
        }
        &_xxs {
            padding: var(--space-xxs);
        }
        &_xs {
            padding: var(--space-xs);
        } 
        &_sm {
            padding: var(--space-sm);
        }
        &_md {
            padding: var(--space-md);
        }
        &_lg {
            padding: var(--space-lg);
        }
        &_xl {
            padding: var(--space-xl);
        }
        &_xxl {
            padding: var(--space-xxl);
        }
        &_xxxl {
            padding: var(--space-xxxl);
        }
        &_xxxxl {
            padding: var(--space-xxxxl);
        }

        &_b {
            &_xxxxs {
                padding-bottom: var(--space-xxxxs);
            }
            &_xxxs {
                padding-bottom: var(--space-xxxs);
            }
            &_xxs {
                padding-bottom: var(--space-xxs);
            }
            &_xs {
                padding-bottom: var(--space-xs);
            } 
            &_sm {
                padding-bottom: var(--space-sm);
            }
            &_md {
                padding-bottom: var(--space-md);
            }
            &_lg {
                padding-bottom: var(--space-lg);
            }
            &_xl {
                padding-bottom: var(--space-xl);
            }
            &_xxl {
                padding-bottom: var(--space-xxl);
            }
            &_xxxl {
                padding-bottom: var(--space-xxxl);
            }
            &_xxxxl {
                padding-bottom: var(--space-xxxl);
            }
        }
        &_t {
            &_xxxxs {
                padding-top: var(--space-xxxxs);
            }
            &_xxxs {
                padding-top: var(--space-xxxs);
            }
            &_xxs {
                padding-top: var(--space-xxs);
            }
            &_xs {
                padding-top: var(--space-xs);
            } 
            &_sm {
                padding-top: var(--space-sm);
            }
            &_md {
                padding-top: var(--space-md);
            }
            &_lg {
                padding-top: var(--space-lg);
            }
            &_xl {
                padding-top: var(--space-xl);
            }
            &_xxl {
                padding-top: var(--space-xxl);
            }
            &_xxxl {
                padding-top: var(--space-xxxl);
            }
            &_xxxxl {
                padding-top: var(--space-xxxxl);
            }
        }
        &_r {
            &_xxxxs {
                padding-right: var(--space-xxxxs);
            }
            &_xxxs {
                padding-right: var(--space-xxxs);
            }
            &_xxs {
                padding-right: var(--space-xxs);
            }
            &_xs {
                padding-right: var(--space-xs);
            } 
            &_sm {
                padding-right: var(--space-sm);
            }
            &_md {
                padding-right: var(--space-md);
            }
            &_lg {
                padding-right: var(--space-lg);
            }
            &_xl {
                padding-right: var(--space-xl);
            }
            &_xxl {
                padding-right: var(--space-xxl);
            }
            &_xxxl {
                padding-right: var(--space-xxxl);
            }
            &_xxxxl {
                padding-right: var(--space-xxxxl);
            }
        }
        &_l {
            &_xxxxs {
                padding-left: var(--space-xxxxs);
            }
            &_xxxs {
                padding-left: var(--space-xxxs);
            }
            &_xxs {
                padding-left: var(--space-xxs);
            }
            &_xs {
                padding-left: var(--space-xs);
            } 
            &_sm {
                padding-left: var(--space-sm);
            }
            &_md {
                padding-left: var(--space-md);
            }
            &_lg {
                padding-left: var(--space-lg);
            }
            &_xl {
                padding-left: var(--space-xl);
            }
            &_xxl {
                padding-left: var(--space-xxl);
            }
            &_xxxl {
                padding-left: var(--space-xxxl);
            }
            &_xxxxl {
                padding-left: var(--space-xxxxl);
            }
        }
        &_x {
            &_xxxxs {
                padding-right: var(--space-xxxxs);
                padding-left: var(--space-xxxxs);
            }
            &_xxxs {
                padding-right: var(--space-xxxs);
                padding-left: var(--space-xxxs);
            }
            &_xxs {
                padding-right: var(--space-xxs);
                padding-left: var(--space-xxs);
            }
            &_xs {
                padding-right: var(--space-xs);
                padding-left: var(--space-xs);
            } 
            &_sm {
                padding-right: var(--space-sm);
                padding-left: var(--space-sm);
            }
            &_md {
                padding-right: var(--space-md);
                padding-left: var(--space-md);
            }
            &_lg {
                padding-right: var(--space-lg);
                padding-left: var(--space-lg);
            }
            &_xl {
                padding-right: var(--space-xl);
                padding-left: var(--space-xl);
            }
            &_xxl {
                padding-right: var(--space-xxl);
                padding-left: var(--space-xxl);
            }
            &_xxxl {
                padding-right: var(--space-xxxl);
                padding-left: var(--space-xxxl);
            }
            &_xxxxl {
                padding-right: var(--space-xxxxl);
                padding-left: var(--space-xxxxl);
            }
        }
        &_y {
            &_xxxxs {
                padding-top: var(--space-xxxxs);
                padding-bottom: var(--space-xxxxs);
            }
            &_xxxs {
                padding-top: var(--space-xxxs);
                padding-bottom: var(--space-xxxs);
            }
            &_xxs {
                padding-top: var(--space-xxs);
                padding-bottom: var(--space-xxs);
            }
            &_xs {
                padding-top: var(--space-xs);
                padding-bottom: var(--space-xs);
            } 
            &_sm {
                padding-top: var(--space-sm);
                padding-bottom: var(--space-sm);
            }
            &_md {
                padding-top: var(--space-md);
                padding-bottom: var(--space-md);
            }
            &_lg {
                padding-top: var(--space-lg);
                padding-bottom: var(--space-lg);
            }
            &_xl {
                padding-top: var(--space-xl);
                padding-bottom: var(--space-xl);
            }
            &_xxl {
                padding-top: var(--space-xxl);
                padding-bottom: var(--space-xxl);
            }
            &_xxxl {
                padding-top: var(--space-xxxl);
                padding-bottom: var(--space-xxxl);
            }
            &_xxxxl {
                padding-top: var(--space-xxxxl);
                padding-bottom: var(--space-xxxxl);
            }
        }
    }
}
