/*edge and IE*/
.atoms_slider {
    &::-ms-track {
        background-color: var(--color-primary-lighter); /*trackColor*/
        height: 2px; /*trackHeight*/
        border: none;
        color: transparent;
    }

    &::-ms-thumb {
        background:#fff; /*thumbColor*/
        width: calc(var(--slider-thumb-size) - 8px);  /*thumbHeight*/
        height: calc(var(--slider-thumb-size) - 8px);  /*thumbHeight*/
        border-radius: 50%;
        border: none;
        margin: 0 4px 0; /*0 thumbBorderWidth 0*/
        box-shadow: 0 0 0 4px  var(--color-primary); /*0 0 0 thumbBorderWidth #fff, box-shadow color should match the background*/
        /*cursor:pointer; doesn't work*/
    }

    &::-ms-tooltip {
        display: none; /*tooltip makes thumb sliding lagy*/
    }

    &::-ms-fill-lower {
        background-color: var(--color-primary); /*thumbColor*/
        border-radius: 4px; /*trackHeight*/
    }
}