.text {
    &-danger {
        color: $danger !important;
    }
    &-weight {
        &_normal {
            font-weight: normal;
        }
        &_bold {
            font-weight: bold;
        }
    }
}