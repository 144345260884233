
.table {
	th,
	td {
		padding:  var(--space-xxs);
		font-weight: normal;
		vertical-align: middle;
	}
	thead {
		th {

			color: $font-lighter-color;
			font-size: 0.875rem;
			border-top: none;
		} 
	}
	tr {
		&:last-child {
			td, th {
				border-bottom: 1px solid #dee2e6;
			}
		}
	}
	&-sm {
		th,
		td {
			padding: var(--space-xxxxs);
		}
	}
	&-lg {
		th,
			td {
				padding:  var(--space-xs);
			}
	}
	&-xl {
	th, td {
			padding: var(--space-md);
		}
	}
	
}