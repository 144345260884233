.atoms-radio {
    display: inline-block;
    display: inline-flex;
    -ms-flex-align: baseline;
    align-items: baseline;
    user-select: none;
    /*TODO Надо удалить line-height 21px lkz body и заменить значение переменной. */
    line-height: 1rem;
    cursor: pointer;
    &:before {
        position: relative;
        top: var(--atoms-radio-translate-y);
        display: inline-block;
        width: var(--atoms-radio-size);
        height: var(--atoms-radio-size);
        flex-shrink: 0;
        margin-right: var(--space-xxs);
        border: 1px solid var(--color-primary);
        border-radius: 50%;
        box-sizing: border-box;
        content: '';   
        background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+PGcgY2xhc3M9J25jLWljb24td3JhcHBlcicgZmlsbD0nI2ZmZmZmZic+PGNpcmNsZSBjeD0nOCcgY3k9JzgnIHI9JzgnIGZpbGw9JyNmZmZmZmYnPjwvY2lyY2xlPjwvZz48L3N2Zz4=);
        background-size: var(--radio-marker-size);
    }
}
.active .atoms-radio{
    font-weight: 500;
    
    &:before {
        background-color: var(--color-primary);
        background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+PGcgY2xhc3M9J25jLWljb24td3JhcHBlcicgZmlsbD0nI2ZmZmZmZic+PGNpcmNsZSBjeD0nOCcgY3k9JzgnIHI9JzgnIGZpbGw9JyNmZmZmZmYnPjwvY2lyY2xlPjwvZz48L3N2Zz4=);
        background-size: 6px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }
}

.disabled .atoms-radio {
    pointer-events: none;
    
    &:before {
        border-color: var(--color-secondary-dark);
    }
}
