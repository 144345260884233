// --------------------------------

// (START) Global Editor code https://codyhouse.co/ds/globals/buttons

// --------------------------------

:root{
  --btn-font-size: 14px;
  --btn-font-size-lg: 1rem;
  --btn-padding-x: var(--space-xxs);
  --btn-padding-y: var(--space-xxxs);
  --btn-radius: 0.25em;
}
atoms_button-ui.button {
  display: inline-flex;
}
.button { // basic button style
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: middle;
  line-height: 1;
  font-size: var(--btn-font-size, 1em);
  padding-top: var(--btn-padding-y, 0.5em);
  padding-bottom: var(--btn-padding-y, 0.5em);
  padding-left: var(--btn-padding-x, 0.75em);
  padding-right: var(--btn-padding-x, 0.75em);
  border-radius: var(--btn-radius, 0.25em);
  &:focus {
    outline: none;
  }
}

.button, .btn {
  cursor: pointer;
  border: 1px solid var(--color-contrast-low);
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
  line-height: 1.125rem;
  box-shadow: none;
  outline: none;

  &:hover {
    opacity: 0.6;
  }

  &:active {
    opacity: 1;
  }

  &--primary {
    background-color: var(--color-primary);
    color: var(--color-white);
    border: 1px solid var(--color-primary);
  }
  &--subtle {
    background-color: var(--color-contrast-low);
    color: var(--color-contrast-higher);
  }
  &--accent {
    background-color: var(--color-accent);
    color: var(--color-white);
    border: 1px solid var(--color-accent);
  }
  &--outline-primary {
    border: 1px solid alpha(var(--color-primary), 0.4);
    background: none;
    
    &:hover {
      opacity: 1;
      border: 1px solid alpha(var(--color-primary), 0.7);
    }
  
    &:active {
      background-color: alpha(var(--color-primary), 0.15);
    }
  
    &.active {
      background-color: alpha(var(--color-primary), 0.15);
    }
    
    &.button--disabled, &.btn--disabled, &:disabled {
      background: none;
      border: 1px solid alpha(var(--color-primary), 0.3);
    }
    
  }
  &--outline-accent {
    border: 1px solid alpha(var(--color-accent), 0.4);
    background: none;
    
    &:hover {
      opacity: 1;
      border: 1px solid alpha(var(--color-accent), 0.7);
    }
  
    &:active {
      background-color: alpha(var(--color-accent), 0.15);
    }
  
    &.active {
      background-color: alpha(var(--color-accent), 0.15);
    }
    
    &.button--disabled, &.btn--disabled, &:disabled {
      background: none;
      border: 1px solid alpha(var(--color-accent), 0.3);
    }
    
  }
  &--disabled {
    opacity: 0.6;
    cursor: not-allowed;
  
    &:active {
      opacity: 0.6;
    }
    &:hover {
      opacity: 0.6;
    }
  }

  // size
  &--sm {
    padding: var(--space-xxxxs) var(--space-xxxs);
  }
  
  &--lg {
    padding: var(--space-xxs) var(--space-sm);
    line-height: 20px;
  }

  & atoms_svg-icon-form {
    display: inline-flex;
    align-items: center;
  }

  &--text-primary {
    border: none;
    background: none;

    &:hover {
      background: var(--color-primary);
      color: var(--color-white);
    }

    &:active {
      background-color: alpha(var(--color-primary), 1);
      color: var(--color-white);
    }

    &.active {
      background-color: alpha(var(--color-primary), 1);
      color: var(--color-white);
    }

    &.button--disabled, &.btn--disabled, &:disabled {
      background: var(--color-primary);
      color: var(--color-white);
    }

  }
  &--text-accent {
    border: none;
    outline: none;
    background: none;

    &:hover {
      background: var(--color-accent);
      color: var(--color-white);
    }

    &:active {
      background-color: alpha(var(--color-accent), 1);
      color: var(--color-white);
    }

    &.active {
      background-color: alpha(var(--color-accent), 1);
      color: var(--color-white);
    }

    &.button--disabled, &.btn--disabled, &:disabled {
      background: var(--color-accent);
      color: var(--color-white);
    }
  }
}

// Кнопка с иконкой 

.button, .btn {
  &--icon {
    &-secondary {
      border: 0;
      background: none;
      color: var(--color-secondary);
    }
    &-base {
      border: 0;
      background: none;
    }
    &-primary {
      border: 0;
      background: none;
      color: var(--color-primary);
    }
    &-success {
      border: 0;
      background: none;
      color: var(--color-success);
    }
    &-danger {
      border: 0;
      background: none;
      color: var(--color-danger);
    }
    &-warning {
      border: 0;
      background: none;
      color: var(--color-warning);
    }
    &-accent {
      border: 0;
      background: none;
      color: var(--color-accent);
    }
  }
}
