.atoms-divider {
    margin: var(--space-xxs) auto;
    display: flex;
    text-align: center;
    align-items: center;
  
    &::before, &::after {
      content: '';
      display: inline-block;
      height: 1px;
      width: 20px;
      flex-grow: 1;
      background: var(--color-contrast-low);
      vertical-align: middle;
    }
    &--md {
      margin: var(--space-xxs) auto;
    }

    &--unset {
      margin: 0 auto;
    }
}
