.btn{
    &-danger{
        @include button-state-disabled(false) {

            @include button-state-hover {
                @include box-shadow($button-style-shadow);
                background-color: $button-style-danger-bg-color;
                border-color: $button-style-danger-border-color;
                opacity: 0.6;
            }
            @include button-state-focus-active {
                @include box-shadow($button-style-shadow);
                color: $button-style-danger-color-active;
                border-color: $button-style-danger-border-color-active;
                background-color: $button-style-danger-bg-color-active;
                opacity: 1;
            }	
        }
        @include button-state-disabled(true) {
            color: $button-style-danger-color;
            background-color: $button-style-danger-bg-color-hover;
            border-color: $button-style-danger-bg-color-hover;
        }
    }

    &-outline{
        &-danger {
            @include button-state-disabled(false) {
                @include button-state-hover {
                    color: $button-style-danger-color-active;
                    border-color: $button-style-danger-border-color-active;
                    background-color: $button-style-outline-danger-bg-color-hover;
                }
                @include button-state-active{
                    color: $button-style-danger-color-active;
                    border-color: $button-style-danger-border-color-active;
                    background-color: $button-style-danger-bg-color-active;
                }
                
                @include button-state-focus-active {
                    color: $button-style-danger-color-active;
                    border-color: $button-style-danger-border-color-active;
                    background-color: $button-style-danger-bg-color-active;
                    box-shadow: none;
                }
            }
        }
    }
}