.tab {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.4);
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 13px;

    white-space: nowrap;
    display: inline-flex;
    align-items: bottom;
    text-align: center;
    color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    margin-right: 24px;
    line-height: 19px;
    &:hover {
        color: var(--color-primary-light);
    }
    &_active {
        color: var(--color-primary-light);
        border-bottom: 3px solid var(--color-primary-light);
    }
    &:last-child {
        margin-right: 0;
    }
    &s {
        display: flex;
        margin-bottom: -1px;
        padding-top: 9px;

        &_right-align {
            justify-content: flex-end;
        }
        &-wrapper {
            .dropdown-menu{
                padding: 0;
                margin: 0;
                border-radius: 0;
            }
        }
        &__header {
            border-bottom: 1px solid var(--color-border-gray);
        }
    }
}