.molecules-tag {
    display: inline-flex;
    align-items: center;
    line-height: 1rem;
    font-size: 14px;
    margin-right: var(--space-xxs);
   
    &__container {
        padding: var(--space-xxxxs) var(--space-xxs);
        display: inline-flex;
        align-items: center;
    }
    &__left-icon {
        display: flex;
        padding-right: var(--space-xxxs);
    }
    &__right-icon {
        display: flex;
        padding-left: var(--space-xxxs);
    }
    &__remove {
        padding-right: var(--space-xxxxs);
    }
    &--sm {
        .molecules-tag__container {
            padding-top: var(--space-xxxxs);
            padding-bottom: var(--space-xxxxs);
        }
        
    }
    &--md {
        .molecules-tag__container {
            padding-top: var(--space-xxxs);
            padding-bottom: var(--space-xxxs);
        }
    }
    &--lg {
        line-height: 1rem;
        font-size: 1rem;
        .molecules-tag__container {
            padding: var(--space-xxs) var(--space-sm);
        }
    }
    &--removable {
        .molecules-tag__container {
            padding-right: 0;
        }
        
    }

    &--view {
        &--outline {
            border: 1px solid;
        }
    }
    &--not-readonly {
        cursor: pointer;
        &:hover {
            border-color: #EDF1FC !important;
            background-color: #EDF1FC;
        }
    }

}