.btn {
	&-lg {
		@include button-size($button-size-padding-y-lg, $button-size-padding-x-lg, $button-typography-font-size, $button-typography-line-height-lg);
	}
	&-sm {
		@include button-size($button-size-padding-y-sm, $button-size-padding-x-sm, $button-typography-font-size, $button-typography-line-height);
	}
	&-xs {
		@include button-size($button-size-padding-y-xs, $button-size-padding-x-xs, $button-typography-font-size-xs, $button-typography-line-height-xs);
	}
	&-icon {
		@include button-size($button-icon-size-padding-y-sm, $button-icon-size-padding-x-sm, $button-typography-font-size, $button-icon-typography-line-height);

		&-lg {
			@include button-size($button-icon-size-padding-y-lg, $button-icon-size-padding-x-lg, $button-typography-font-size, $button-icon-typography-line-height);
		}
		&-sm {
			@include button-size($button-icon-size-padding-y-sm, $button-icon-size-padding-x-sm, $button-icon-typography-font-size, $button-icon-typography-line-height);
		}
	}
}