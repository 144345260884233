//Button link colors

    //default
        $button-link-default-bg-color: transparent; // Основной цвет кнопки
        $button-style-link-bg-color: $button-link-default-bg-color; //Основной цвет фона кнопки
        $button-style-link-color: $primary; // Основной цвет текста кнопки
        $button-style-link-border-color: $button-link-default-bg-color; // Основной цвет обводки 

    //hover
        $button-style-link-bg-color-hover: transparentize($button-link-default-bg-color,.6); // Цвет фона при наведени
        $button-style-link-border-color-hover: transparentize($button-link-default-bg-color,.6); // Цвет обводки при наведени
        $button-style-link-color-hover:  $button-style-link-color; // Цвет текста при наведении
    //active
        $button-style-link-bg-color-active:  $button-link-default-bg-color; // Цвет фона при active
        $button-style-link-border-color-active:  $button-link-default-bg-color; // Цвет обводки при active
        $button-style-link-color-active:  $button-style-link-color; // Цвет текста при active

