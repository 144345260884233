$buttons-size-lg: 48px;

$table-cell-padding: .75rem;
$table-cell-padding-sm: .3rem;
$table-cell-padding-lg: 1rem;
$table-cell-padding-xl: 1.5rem;


$h1-font-size: 1.625rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.3rem;
$h4-font-size: 1rem;

:root {
    --h-grid-size-point: 0.5rem;
    --h-grid-size-scale: 7;
    --space-unit:  1rem;

}