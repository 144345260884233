.modal{
    &-content{
        border: none;
        box-shadow: 0 8px 24px 0 rgba(22, 102, 167, 0.2);
        padding: var(--space-md) var(--space-md) 0;
    }
    &-backdrop{
        &.show{
            opacity: 0.9;
        }
        background: #fff;
    }
    &-header{
        padding: 0;
        border: none;
    }
    &-body{
        padding: var(--space-xxxs) 0 0;
        min-height: auto;
        overflow-y: initial;
    }
    &-footer{
        padding: 0 0 var(--space-md);
        border: none;
    }
    &-title{
        color: $color-brand;
        font-size: 2rem;
        line-height: 1.25;
    }
}
.custom-modal-dialog.modal .modal-body{
    min-height: auto;
    overflow-y: initial;
}