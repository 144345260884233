$button-typography-weight: 400;
$button-typography-align: center;
$button-typography-font-size: 14px;
$button-typography-line-height: 1.125rem;

$button-typography-font-size-lg: 1rem;
$button-typography-line-height-lg: 1.5rem;

$button-typography-font-size-sm: 1rem;
$button-typography-line-height-sm: 1.5rem;

$button-typography-font-size-xs: 0.687rem;
$button-typography-line-height-xs: 0.812rem;

$button-icon-typography-line-height: 1rem;
$button-icon-typography-font-size: 1rem;

