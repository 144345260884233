.accordion{
    display: block;
    margin-bottom: 3rem;
    .card{
        border: 1px solid var(--color-border-gray);
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 8px;
        
        &:not(:first-of-type):not(:last-of-type){
            border-radius: 3px;
            border-bottom: 1px solid var(--color-border-gray);
            &:hover {
                border-color: rgba(0, 0, 0, 0.5);
            }
        }
        &:first-of-type{
            border-radius: 3px;
            border-bottom: 1px solid var(--color-border-gray);
            &:hover {
                border-color: rgba(0, 0, 0, 0.5);
            }
        }
        &:last-of-type{
            border-radius: 3px;
            border-bottom: 1px solid var(--color-border-gray);
            &:hover {
                border-color: rgba(0, 0, 0, 0.5);
            }
        }
        &:hover {
            border-color: rgba(0, 0, 0, 0.5);
        }
        &-header{
            padding: 0;
            background: #fff;
            border: none;
            .btn-link{
                width: 100%;
                text-align: left;
                padding: 0;
                color: $font-dark-color;
                position: relative;
                padding: 20px;
                padding-right: 40px;
                @include hover-focus{
                    text-decoration: none;
                    color: $font-dark-color !important;
                    opacity: 1 !important;
                }
                &:before {
                    content: "\e90e";
                    top: 50%;
                    margin-top: -6px;
                    right: 25px;
                    font-family: 'icomoon';
                    position: absolute;
                    font-size: 9px;
                    line-height: 9px;
                    transform: rotate(-90deg);
                }
                &:not(.collapsed) {
                    &:before {

                        transform: rotate(0);
                    }
                }
            }
        }
        &-body{
            border-top: 0;
            padding-top: 0;
        }
    }
   
}