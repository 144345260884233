atoms_svg-icon-form {
  display: inline-flex;
}
:root {
  // size
  --icon-xxxs: 8px;
  --icon-xxs: 12px;
  --icon-xs:  16px;
  --icon-sm:  24px;
  --icon-md:  32px;
  --icon-lg:  48px;
  --icon-xl:  64px;
  --icon-xxl: 128px;
}

// icon size
.icon--xxxs { font-size: var(--icon-xxxs); }
.icon--xxs { font-size: var(--icon-xxs); }
.icon--xs  { font-size: var(--icon-xs); }
.icon--sm  { font-size: var(--icon-sm); }
.icon--md  { font-size: var(--icon-md); }
.icon--lg  { font-size: var(--icon-lg); }
.icon--xl  { font-size: var(--icon-xl); }
.icon--xxl { font-size: var(--icon-xxl); }