:root {
    --hover: var(--color-secondary);
    --active: var(--color-secondary-dark);
}

.atoms-hover {
    &--default {
        cursor: pointer;
        &.atoms-hover--active {
            background-color: var(--hover);
        }
    }
   
    &--background-none {
        cursor: pointer;

    }
}
.atoms-active {
    &--default {
        cursor: pointer;
        &.atoms-active--active {
            background-color: var(--active);
        }
    }
   
    &--background-none {
        cursor: pointer;

    }
}