.button-group{
    &__item-first {
        & .button {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0;
        }
    }
    &__item-last {
        & .button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    &__item{
        & .button {
            border-radius: 0;
            border-right: 0;
        }
    }
}